$primary-color: #5654db;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar { 
    width: 12px;
    background-color: $primary-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 2px solid $primary-color;
    background-clip: content-box;
    background-color: white;
  }
}

body {
  height: 100%;
  font-size: 1rem;
  font-family: Roboto, sans-serif;
}

html, body {
  height: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li {
  margin: 0;
}

ul,
ol {
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 3.125rem;
  font-weight: 700;
}

.button {
  cursor: pointer;
  color: white;
  border: .125rem solid;
  border-radius: .3rem;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: 1.5rem;
  display: block;
  text-align: center;
  min-width: 18.5rem;
  padding: 1rem 2rem;
  transition: .2s ease;
  &:hover {
    background-color: white;
    border-color: white;
    color: $primary-color;
  }
}

.container {
  max-width: 1040px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.center {
  text-align: center;
}

.bg-primary {
  background-color: $primary-color;
}

.custom-snack-bar {
  color: white;
  background-color: #c62828;
}
